<script setup>
const props = defineProps(['value', 'uom', 'sale', 'clearance']);
const classes = props.sale || props.clearance ? "line-through text-red-600" : "";
</script>

<template>
    <span :class="classes">
        ${{ (value / 100).toFixed(2) }}
        <small v-if="uom">/{{ uom }}</small>
    </span>
</template>
